<template>
  <div class="container-component">
    <div class="container-left">
      <div class="left-tree">
        <div class="tree-title">
          <span>角色列表</span>
        </div>
        <div class="tree-list">
          <el-tree ref="tree" :data="departmentTree" node-key="id" empty-text="暂无部门" :show-checkbox="false" :highlight-current="true" @node-click="checkNode"></el-tree>
        </div>
      </div>
    </div>
    <div class="container-right">
      <div class="right-top">
        <el-button type="success" size="small" @click="open('add')" icon="el-icon-plus">新增角色({{nodeName||'暂未选择部门'}})</el-button>
      </div>
      <div class="right-center">
        <el-table :data="roleList" stripe style="width: 100%" height="100%">
          <el-table-column label="序号" type="index" width="100" :index='indexMethod'> </el-table-column>
          <el-table-column prop="name" label="角色名称" show-overflow-tooltip>
          </el-table-column>
          <el-table-column label="操作" show-overflow-tooltip>
            <template slot-scope="scope">
              <el-tooltip content="修改" placement="top" effect="dark">
                <el-button type="warning" circle icon="el-icon-edit" size="small" @click="open('edit',scope.row)"></el-button>
              </el-tooltip>
              <el-tooltip content="删除" placement="top" effect="dark">
                <el-button type="danger" circle icon="el-icon-delete" size="small" @click="delRole(scope.row.id)"></el-button>
              </el-tooltip>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="right-bottom">
        <el-pagination @current-change="handleCurrentChange" :current-page.sync="currentPage" :page-size="10" layout="total, prev, pager, next, jumper" :total="total">
        </el-pagination>
      </div>
    </div>
    <!-- 表单 -->
    <el-dialog :title="optFlag?'添加角色':'修改角色'" :visible.sync="roleShow" width="30%" v-dialogDrag :close-on-click-modal="false">
      <el-form :model="role" ref="roleForm" :rules="rules" label-width="80px">
        <el-form-item label="所属部门">
          <span>{{nodeName}}</span>
        </el-form-item>
        <el-form-item label="角色名称" prop="name">
          <el-input v-model="role.name" size="small" placeholder="请输入角色名称"></el-input>
        </el-form-item>
      </el-form>
      <span>
        <el-button @click="roleShow = false">取 消</el-button>
        <el-button type="primary" @click="addRole" v-show="optFlag">添 加</el-button>
        <el-button type="primary" @click="editRole" v-show="!optFlag">修 改</el-button>
      </span>
    </el-dialog>

  </div>
</template>

<script>
export default {
  data () {
    return {
      roleList: [],
      departmentTree: [],
      currentPage: 1,
      total: 0,
      roleShow: false,
      role: {},
      roleId: '',
      departmentId: '',
      optFlag: false,
      nodeName: '',
      rules: {
        name: [
          { required: true, message: '请输入角色名称', trigger: 'blur' }
        ]
      }
    }
  },
  created () {
    this.initTree()
  },
  methods: {
    indexMethod (index) {
      const curpage = this.currentPage // 单前页码，具体看组件取值
      const limitpage = 10 // 每页条数，具体是组件取值
      return index + 1 + (curpage - 1) * limitpage
    },
    handleCurrentChange (val) {
      this.currentPage = val
      this.initTable()
    },
    checkNode (node) {
      this.nodeName = node.label
      this.departmentId = node.id
      this.initTable()
    },
    async initTable () {
      const data = {}
      data.pageSize = 10
      data.pageNo = this.currentPage
      data.departmentId = this.departmentId
      const { data: result } = await this.$axios.get('/unitRole/list', { params: data })
      if (result.code === 200) {
        this.roleList = result.data.result
        this.total = result.data.total
      } else {
        this.$message.error(result.msg)
      }
    },
    async initTree () {
      const { data: result } = await this.$axios.post('/department/treeDepartment')
      if (result.code === 200) {
        this.departmentTree = result.data
        this.departmentId = result.data[0].id
        this.initTable()
      }
    },
    open (flag, row) {
      switch (flag) {
        case 'add':
          this.optFlag = true
          this.roleShow = true
          break
        case 'edit':
          this.optFlag = false
          this.roleShow = true
          this.$nextTick(() => {
            this.$refs.roleForm.clearValidate()
          })
          this.role = JSON.parse(JSON.stringify(row))
          break
      }
    },
    // 添加角色
    addRole () {
      this.$refs.roleForm.validate(async valid => {
        if (valid) {
          this.role.departmentId = this.departmentId
          const { data: result } = await this.$axios.post('/unitRole/add', this.role)
          if (result.code === 200) {
            this.$message.success('添加成功')
            const totalPage = Math.ceil((this.total + 1) / 10) // 总页数
            this.currentPage = this.currentPage > totalPage ? totalPage : this.currentPage
            this.currentPage = this.currentPage < 1 ? 1 : this.currentPage
            this.roleShow = false
            this.initTable()
          } else {
            this.$message.error(result.msg)
          }
        }
      })
    },
    // 修改角色
    editRole () {
      this.$refs.roleForm.validate(async valid => {
        if (valid) {
          this.role.departmentId = this.departmentId
          const { data: result } = await this.$axios.put(`/unitRole/edit/${this.role.id}`, this.role)
          if (result.code === 200) {
            this.$message.success('修改成功')
            this.roleShow = false
            this.initTable()
          } else {
            this.$message.error(result.msg)
          }
        }
      })
    },
    // 删除角色
    async delRole (id) {
      const confirm = await this.$confirm('此操作将永久删除该角色, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).catch(err => err)
      if (confirm === 'confirm') {
        const { data: result } = await this.$axios.delete(`/unitRole/del/${id}`)
        if (result.code === 200) {
          this.$message.success('删除成功')
          const totalPage = Math.ceil((this.total - 1) / 10) // 总页数
          this.currentPage = this.currentPage > totalPage ? totalPage : this.currentPage
          this.currentPage = this.currentPage < 1 ? 1 : this.currentPage
          this.initTable()
        } else {
          this.$message.error(result.msg)
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.container-component {
  height: 100%;
  width: 100%;
  background-color: transparent;
  padding: 10px 10px 0 10px;
  display: flex;
  justify-content: space-between;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
  box-sizing: border-box;
  position: relative;
  background-image: url("../../../assets/img/table-bg.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  .container-left {
    flex: 1;
    margin-right: 10px;
    padding-bottom: 10px;
    .left-tree {
      height: 100%;
      .tree-title {
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
      }
      .el-tree {
        background-color: transparent;
        color: #fff;
        ::v-deep .el-tree-node__content:hover {
          background-color: rgba(30, 74, 115, 0.5);
        }
        ::v-deep .el-tree-node:focus > .el-tree-node__content {
          background-color: rgba(30, 74, 115, 0.5);
        }
      }
    }
  }
  .container-right {
    flex: 4;
    display: flex;
    flex-direction: column;
    .right-top {
      flex: 1;
      display: flex;
      align-items: center;
      height: 40px;
      justify-content: flex-start;
      .el-button {
        margin-right: auto;
      }
      .el-form {
        height: 40px;
      }
    }
    .right-center {
      flex: 9;
      ::v-deep .el-table,
      .el-table__expanded-cell {
        background-color: transparent;
        tr,
        th {
          background-color: transparent;
          color: #fff;
          .el-table__expand-icon {
            color: #fff;
          }
        }
        .el-table--enable-row-hover,
        .hover-row > td,
        tr:hover > td {
          background-color: rgba(28, 97, 160, 0.8) !important;
        }
        .el-table__row--striped > td {
          background-color: rgba(44, 74, 130, 0.2) !important;
        }
        .current-row > td {
          background-color: rgba(7, 104, 159, 0.5) !important;
        }
      }
    }
    .right-bottom {
      flex: 1;
      display: flex;
      align-items: center;
      ::v-deep .el-pagination {
        color: #fff;
        background-color: transparent;
        .el-pagination__total,
        .el-pagination__jump {
          color: #fff;
        }
        .btn-prev,
        .btn-next {
          background-color: transparent;
          color: #fff;
        }
        .el-pager li {
          background-color: transparent !important;
        }
        .btn-quicknext {
          color: #fff;
        }
      }
    }
  }
}
</style>
